import Map from './Map'
import Coming from './Coming'
import Accomodation from './Accomodation'
import Interests from './Interests'

const Travel = () => {
  return (
    <div id="travel">
      <Map />
      <Coming />
      <Accomodation />
      <Interests />
    </div>
  )
}

export default Travel
