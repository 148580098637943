import { REGISTRY } from '../constants/routes'
import { Button } from 'react-bootstrap'

const RegistryButton = () => {
  return (
    <Button href={REGISTRY} target="_blank" rel="noopener noreferrer">
      Je participe
    </Button>
  )
}

export default RegistryButton
