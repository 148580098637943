import { useRef, useState } from 'react'
import useHtmlResize from './useHtmlResize'

const ENGAGEMENT_IMAGE_SIZE = {
  lg: 200,
  xs: 150,
}
const INVITATION_CARD_WIDTH = 430
const INVITATION_CARD_HEIGHT = 652
const INVITATION_CARD_RATIO = INVITATION_CARD_WIDTH / INVITATION_CARD_HEIGHT

function calculateInvitationCardSize(
  containerWidth: number,
  containerHeight: number
) {
  const containerRatio = containerWidth / containerHeight

  if (containerRatio < INVITATION_CARD_RATIO) {
    return {
      width: containerWidth,
      height: containerWidth / INVITATION_CARD_RATIO,
    }
  }

  return {
    width: containerHeight * INVITATION_CARD_RATIO,
    height: containerHeight,
  }
}

const usePositionEngagementImage = () => {
  const [positionProperties, setPositionProperties] = useState<{
    top: number
    left: number
    width: number
    height: number
    paddingBottom: number
  } | null>(null)
  const ref = useRef<HTMLImageElement | null>(null)

  const handleResize = (boundingRect: DOMRect) => {
    const mode: 'xs' | 'lg' = window.innerWidth < 480 ? 'xs' : 'lg'
    const engagementImageSize = ENGAGEMENT_IMAGE_SIZE[mode]
    const paddingBottom = engagementImageSize / 2
    const containerHeight = boundingRect.height - paddingBottom
    const containerWidth = boundingRect.width

    const { width, height } = calculateInvitationCardSize(
      containerWidth,
      containerHeight
    )

    if (mode === 'xs') {
      return setPositionProperties({
        top: containerHeight - (containerHeight - height) / 2,
        left: containerWidth / 2,
        width: engagementImageSize,
        height: engagementImageSize,
        paddingBottom,
      })
    }

    setPositionProperties({
      top: containerHeight - (containerHeight - height) / 2,
      left: (containerWidth - width) / 2,
      width: engagementImageSize,
      height: engagementImageSize,
      paddingBottom,
    })
  }

  useHtmlResize({
    ref,
    onResize: handleResize,
  })

  return {
    ref,
    positionProperties,
  }
}

export default usePositionEngagementImage
