import { Route, Routes, useLocation } from "react-router-dom";

import { ROUTES } from "./constants/routes";

import HomePage from "./pages/Home";
import PlanningPage from "./pages/Planning";
import TravelPage from "./pages/Travel";
import DetailsPage from "./pages/Details";
import RegistryPage from "./pages/Registry";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import UpcomingPage from "./pages/Upcoming";
import { useLayoutEffect } from "react";

const VALID_HASHES = ["#coming", "#accomodation", "#interests"];
const Y_OFFSET = -50;

function App() {
  const location = useLocation();
  const pageName = location.pathname.split("/")[1] || "home";

  useLayoutEffect(() => {
    if (location.hash.length === 0) {
      window.scrollTo(0, 0);
    }

    if (VALID_HASHES.includes(location.hash)) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        const yPosition =
          element.getBoundingClientRect().top + window.scrollY + Y_OFFSET;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
    }
  }, [location.pathname, location.hash]);

  return (
    <div id="app" className={`main ${pageName}`}>
      <NavBar />

      <main>
        <Routes>
          <Route element={<PlanningPage />} path={ROUTES.PLANNING} />
          <Route element={<TravelPage />} path={ROUTES.TRAVEL} />
          <Route element={<DetailsPage />} path={ROUTES.DETAILS} />
          <Route element={<RegistryPage />} path={ROUTES.REGISTRY} />
          <Route element={<UpcomingPage />} path={ROUTES.UPCOMING} />
          <Route element={<HomePage />} path={ROUTES.HOME} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
