import { Image, Container, Row, Col, Card } from 'react-bootstrap'
import Section from '../../components/Section'
import Party from '../../assets/planning/Party.svg'
import Cocktail from '../../assets/planning/Cocktail.svg'
import Church from '../../assets/planning/Church.svg'

// const Event = ({ title, description }: Props) => {
//   return (
//     <Col lg={4} md={12}>
//       <Card className="text-center border-0">
//         <Card.Body>
//           <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
//             <svg className="bi" width="1em" height="1em" />
//           </div>
//           <Card.Title className="fs-2 text-body-emphasis">{title}</Card.Title>
//           <Card.Text>{description}</Card.Text>
//         </Card.Body>
//       </Card>
//     </Col>
//   )
// }

// const ProgrammeSection = () => {
//   return (
//     <Section className="bg-white">
//       <Container className="px-4 py-5">
//         <h2 className="pb-2 playball">Au programme des festivités</h2>
//         <Row className="g-4 py-5">
//           <Event
//             title="15h - Cérémonie religieuse"
//             description="Église de la Trinité, 40150 Soorts-Hossegor"
//           />
//           <Event
//             title="17h - Cocktail & Dîner"
//             description="Château de Larraldia, 64990 Villefranque"
//           />
//           <Event
//             title="12h - Brunch"
//             description="Château de Larraldia, 64990 Villefranque"
//           />
//         </Row>
//       </Container>
//     </Section>
//   )
// }

interface EventProps {
  title: string
  subtitle: string
  imgUrl: string
}

const Event = ({ title, subtitle, imgUrl }: EventProps) => {
  return (
    <Col
      className="d-flex flex-column justify-content-center align-items-center"
      lg={4}
      md={12}
    >
      <Image
        src={imgUrl}
        roundedCircle
        width="140"
        height="140"
        alt="Placeholder"
        className="bg-sand mt-2 mb-5"
      />
      <Card.Body className="text-center">
        <Card.Title className="marron">{title}</Card.Title>
        <Card.Text className="my-2">{subtitle}</Card.Text>
      </Card.Body>
    </Col>
  )
}

const ProgrammeSection = () => {
  return (
    <Section className="bg-white planning-home">
      <Container className="px-4 py-5">
        <h2 className="pb-2">Au programme des festivités</h2>
        <Row className="g-4 py-5">
          <Event
            title="15h - Cérémonie religieuse"
            subtitle="Église de la Trinité, 40150 Soorts-Hossegor"
            imgUrl={Church}
          />
          <Event
            title="17h - Cocktail & Dîner"
            subtitle="Château de Larraldia, 64990 Villefranque"
            imgUrl={Cocktail}
          />
          <Event
            title="00h - Fiesta"
            subtitle="Château de Larraldia, 64990 Villefranque"
            imgUrl={Party}
          />
        </Row>
      </Container>
    </Section>
  )
}

export default ProgrammeSection
