import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

const UpcomingPage = () => {
  return (
    <div id="upcoming">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1>Oups, cette page n'est pas encore disponible&nbsp;!</h1>
        <p>
          Revenez dans quelques semaines, à une date plus proche du mariage et
          elle sera très probablement accessible&nbsp;:)
        </p>
        <Link to={ROUTES.HOME}>
          <Button variant="primary" className="mt-5">
            Retour à l'accueil
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default UpcomingPage
