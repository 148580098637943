const PineCone = `<svg width="101" height="104" viewBox="0 0 101 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69.414 99.2051C69.6155 100.228 69.9641 101.165 70.3684 102.251L72.1112 103.268L73.7207 103.274C73.7207 103.274 83.3399 101.288 84.0797 100.811C84.8195 100.335 86.3392 99.8623 86.3392 99.8623C86.3392 99.8623 87.7136 99.2336 88.4313 98.9231C91.1359 97.7531 91.5202 93.5491 91.5202 93.5491C91.5202 93.5491 92.9769 90.7598 93.548 89.5311C94.1191 88.3023 95.4088 83.9755 95.4088 83.9755C96.0425 82.7409 96.9502 78.8371 96.9502 78.8371L97.9075 76.9403L100.115 71.418C100.115 71.418 100.406 65.9361 98.9093 65.9299C97.4128 65.9237 95.2816 65.8835 95.2816 65.8835L87.8397 67.6006C87.4835 67.6716 87.3128 67.7441 87.0017 67.806C84.5012 69.1144 82.1411 70.4334 79.623 71.8075C79.5056 71.8485 79.4417 71.8447 79.4294 71.8354C79.3457 71.9835 79.2298 72.1434 78.975 72.3499C78.6188 72.624 78.4078 72.8494 78.0854 73.1137C77.5022 73.7471 77.0304 74.3416 76.4148 75.0106C76.0147 75.5155 75.7584 75.9459 75.3832 76.4726C74.8789 77.3837 74.4935 78.1987 73.9831 79.1323C73.7801 79.5809 73.7022 79.9109 73.5172 80.3731C73.4331 80.7294 73.4561 80.9536 73.4262 81.3396C73.3462 85.04 72.5346 87.7824 72.3149 91.4342L72.1485 94.274C72.0148 95.4995 71.4983 95.5339 71.0017 96.5464C70.6972 97.1672 70.2012 98.6622 69.414 99.2051Z" fill="#633C1B"/>
<path d="M31.5903 93.7231C32.618 97.2328 34.7447 99.9398 38.2909 102.42L62.317 100.833C64.114 99.6237 63.3766 99.2487 65.2497 97.8908L65.7567 95.8296C66.1026 94.8891 66.384 94.0021 66.7622 92.9727C66.9132 92.4601 66.9673 92.0898 67.1123 91.5539C67.2341 90.7667 67.2651 90.145 67.3616 89.3818C67.4271 89.2403 67.4593 89.0706 67.5281 89.0184C67.5791 88.8033 67.5424 88.6448 67.5245 88.3719C67.5442 87.7467 67.5261 87.2402 67.5666 86.5825C67.5498 85.8804 67.4744 85.3293 67.415 84.6112C67.3358 83.8735 67.2405 83.3029 67.1947 82.5664C67.1451 82.0445 67.0459 81.6884 66.9441 81.1461C66.8237 80.7103 66.706 80.4609 66.63 80.0766C66.5912 79.7387 66.5105 79.5357 66.4271 79.1677C66.3784 78.9338 66.3325 78.8647 66.2996 78.6721C66.1873 78.3011 66.0622 78.0537 65.9229 77.6665C65.9087 77.5267 65.7397 77.3828 65.7465 77.272C65.7533 77.1612 65.8108 77.1328 65.7789 77.1303C65.4417 76.5641 65.1365 76.0004 64.8176 75.2741C62.7972 72.6929 60.7905 70.2744 58.7271 67.7052C58.5044 67.4725 58.3384 67.3906 58.1187 67.1762C54.9075 65.4319 51.75 63.8199 48.4069 62.2025C48.2839 62.1988 48.2349 62.1769 48.2216 62.1669C47.9947 62.3416 47.7476 62.5011 47.3478 62.6592C47.1941 62.6576 47.1227 62.7447 47.0678 62.7322C43.96 64.8402 40.9072 66.9606 37.7145 69.1584C37.5748 69.2359 37.5243 69.2348 37.5306 69.2592C37.0937 69.8184 36.6507 70.3531 36.0763 70.9648C35.0521 72.4762 34.1593 73.9107 33.1516 75.4653C32.5185 77.0711 32.0003 78.5568 31.3716 80.1822C31.2407 80.5421 31.2203 80.7624 31.1323 81.1351C31.001 81.8517 30.9374 82.4159 30.8019 83.1375C30.5544 85.7001 30.3789 88.1052 30.0505 90.6267L31.5903 93.7231Z" fill="#633C1B"/>
<path d="M16.0424 99.7701C15.5153 99.8451 12.4561 95.6731 12.0432 95.6336C11.6613 95.1899 6.46505 89.6656 6.17338 89.1987C6.01663 88.7378 5.87669 88.4447 5.75248 87.9581C4.77623 85.6517 3.78422 83.5386 2.81004 81.3607C2.82787 81.2958 2.72645 81.2075 2.72949 81.0145C1.90534 76.7957 1.10357 72.7645 0.239454 68.7467C-0.149938 66.9362 0.719441 65.981 2.42909 65.3751C2.81498 65.3735 3.03557 65.381 3.37494 65.5374C5.8366 66.2036 8.17946 66.7209 10.6091 67.3172C10.9223 67.4316 11.1488 67.4672 11.5004 67.6379C13.9214 68.995 16.2174 70.2168 18.5521 71.5562C18.7955 71.7925 19.0002 71.9114 19.2827 72.1841C20.7224 74.005 22.0843 75.6721 23.5041 77.5104C24.1573 78.5068 24.7526 79.332 25.4231 80.293C25.7694 80.5608 26.0406 80.6928 26.3107 80.9678C26.2694 81.3911 26.229 81.6713 26.0805 82.0897C25.8417 82.7476 25.711 83.2674 25.5265 83.9621C25.2915 84.8351 25.1103 85.5332 24.8236 86.3224C24.7146 86.5063 24.7109 86.5991 24.6805 86.7382C24.6538 86.7845 24.6852 86.8866 24.5775 86.9994C24.4554 87.3133 24.441 87.5144 24.3744 87.9081C24.3422 88.629 24.3622 89.1574 24.3473 89.813C24.348 90.0209 24.3835 90.1015 24.3966 90.3719C24.5424 91.4235 24.7107 92.2853 24.8556 93.329C25.1649 94.3871 25.4977 95.2633 25.8287 96.3235C26.1408 97.3189 26.4545 98.1461 26.7942 99.042L26.8415 99.1669C26.8713 99.2541 26.868 99.4384 26.8711 99.6256C26.8919 99.9362 26.9097 100.06 26.8656 100.133C26.8037 100.082 26.9213 100.191 26.7596 100.168C26.351 100.317 26.1042 100.49 25.7798 100.815C25.1882 101.622 24.6742 102.276 23.7933 103.397L22.2489 103.39L16.0424 99.7701Z" fill="#633C1B"/>
<path d="M42.4863 23.6363C42.5222 23.87 42.5581 24.1036 42.4391 24.5672C41.9488 25.113 41.6133 25.4289 41.2779 25.7447C40.5154 26.3563 39.7528 26.9678 38.7576 27.7681C38.205 28.3371 37.8852 28.7175 37.5654 29.0978C37.0398 29.5849 36.5142 30.072 35.7681 30.7711C34.6306 32.1258 33.7136 33.2685 32.7966 34.4111C32.7966 34.4111 32.693 34.4746 32.5335 34.6219C31.7567 35.6608 31.1392 36.5523 30.5218 37.4438C29.9746 37.4708 29.4275 37.4978 28.6526 37.3219C26.759 35.8083 25.093 34.4974 23.427 33.1865C23.427 33.1865 23.3687 33.1882 23.2631 33.0352C22.02 32.2087 20.8824 31.535 19.7448 30.8614C19.7448 30.8614 19.721 30.9084 19.6955 30.7798C19.5096 30.5776 19.3491 30.504 19.1887 30.4303C19.1887 30.4303 19.1739 30.4674 19.1755 30.2636C19.1678 29.5394 19.1584 29.0191 19.1491 28.4987C19.2187 28.3526 19.2883 28.2065 19.4495 27.7896C20.6928 22.3838 23.4319 18.077 26.5987 13.9857C26.5986 13.9779 26.6131 13.9734 26.7402 13.9043C27.4755 13.154 28.0837 12.4729 28.8068 11.7728C29.0832 11.6841 29.1656 11.5613 29.1691 11.3855C31.9419 13.443 34.7755 15.4251 37.4594 17.5926C38.6409 18.5469 39.5179 19.8784 40.7072 21.3016C41.4163 22.2552 41.9513 22.9458 42.4863 23.6363Z" fill="#633C1B"/>
<path d="M91.254 61.8221C90.8659 61.2375 90.4778 60.653 89.9402 59.7857C89.4707 58.9506 89.1507 58.3981 88.801 57.6718C86.1633 53.0499 83.069 49.0157 78.8143 45.7579C77.4793 44.474 76.2287 43.3295 75.1341 42.1222C79.6248 39.3084 83.9595 36.5573 88.2941 33.8061C89.0506 34.179 89.8071 34.5518 90.5883 35.2646C91.6434 39.9219 92.674 44.2392 93.7045 48.5564C93.6865 50.7102 93.6685 52.864 93.6186 55.4479C93.5671 56.7279 93.5476 57.5778 93.528 58.4278C93.5274 59.2618 93.5268 60.0958 93.498 61.138C93.1016 61.3633 92.7333 61.3805 92.0771 61.5343C91.6107 61.7213 91.4324 61.7717 91.254 61.8221Z" fill="#633C1B"/>
<path d="M6.25066 43.7497C6.41191 43.2029 6.57317 42.6562 6.77641 41.8253C6.80716 41.4554 6.79591 41.3696 6.78468 41.2839C7.10353 40.4145 7.42238 39.5451 7.85702 38.3719C9.04877 36.5588 10.1247 35.0496 11.2007 33.5404C11.4468 33.6729 11.6929 33.8055 12.1845 34.0784C15.291 35.8109 18.1518 37.4029 21.0126 38.9949C21.0126 38.9949 21.0814 38.9191 21.1081 39.0947C22.2239 40.1473 23.3129 41.0241 24.4019 41.901C24.3954 42.0913 24.3889 42.2815 24.2494 42.6293C23.8758 42.9945 23.635 43.2021 23.3942 43.4098C23.3942 43.4098 23.1592 43.4847 22.9425 43.6469C20.1487 46.252 17.5716 48.6948 14.9946 51.1377C14.6464 51.5034 14.2982 51.8691 13.7603 52.4521C12.0977 55.1305 10.6247 57.5917 9.15172 60.0529C9.15172 60.0529 9.06022 60.1233 8.94188 60.2753C8.77167 60.6314 8.7198 60.8355 8.66792 61.0395C7.96927 61.0446 7.27063 61.0497 6.3929 60.8236C5.99008 57.4441 5.76636 54.2957 5.54263 51.1474C5.58575 50.6134 5.62887 50.0795 5.70592 49.1469C5.91014 47.0822 6.08039 45.4159 6.25066 43.7497Z" fill="#633C1B"/>
<path d="M51.229 21.1999C48.8587 21.5975 47.7213 20.183 46.4451 18.4308C44.5765 15.8653 42.2768 13.6137 40.0141 11.1774C40.0377 10.1494 40.2078 9.17224 40.5577 7.93513C41.9935 6.28033 43.1795 4.81356 44.52 3.50511C47.6776 0.423068 50.4417 0.131579 53.7848 2.6624C55.5397 3.99087 57.0556 5.63502 58.6794 7.13662C58.7856 7.36996 58.8917 7.60328 59.1434 8.06076C60.3682 9.66217 60.1567 10.6395 58.8805 11.9985C56.1547 14.9009 53.7596 18.114 51.229 21.1999Z" fill="#633C1B"/>
<path d="M63.7903 44.4221C63.6204 44.3231 63.4504 44.2241 63.0209 44.2715C62.6487 44.5707 62.536 44.7234 62.4233 44.8762C62.4233 44.8762 62.4893 44.9037 62.3271 44.8982C61.8926 45.1534 61.6202 45.414 61.3478 45.6745C61.3478 45.6745 61.3359 45.7237 61.1909 45.7656C60.7064 46.1638 60.367 46.5202 60.0275 46.8766C59.93 46.9224 59.8324 46.9682 59.5092 47.2019C59.2077 47.5621 59.1318 47.7345 59.0559 47.9069C59.0559 47.9069 59.0851 47.9025 58.9641 47.9778C58.6535 48.3418 58.4638 48.6305 58.2742 48.9191C56.0346 51.3457 53.7479 53.7311 51.5733 56.2146C50.3254 57.6398 48.885 57.4374 47.9007 56.376C43.6252 51.7653 39.5111 47.0047 35.049 41.9722C38.5876 37.0007 42.6825 31.7017 48.7356 28.3599C49.4275 27.9779 50.9692 28.1903 51.5646 28.733C55.1886 32.0361 58.6526 35.5147 62.3401 39.1736C62.8311 39.7942 63.152 40.176 63.4729 40.5579C64.1796 41.7024 65.1888 42.8355 63.7228 44.2355C63.8403 44.4047 63.7903 44.4221 63.7903 44.4221Z" fill="#633C1B"/>
<path d="M71.6786 73.0827C71.3534 73.1412 71.0283 73.1996 70.5112 73.0209C68.3548 70.4415 66.3903 68.0994 64.4258 65.7572C64.1078 65.4155 63.7899 65.0738 63.2684 64.5526C62.8867 64.2823 62.7085 64.1916 62.5304 64.1009C62.5304 64.1009 62.5251 64.1573 62.4824 63.9941C61.2774 63.0354 60.1151 62.2398 58.9529 61.4442C58.9529 61.4442 58.8587 61.3857 58.7088 61.2443C58.0211 60.6831 57.4832 60.2632 56.9445 59.8426C57.4702 58.9895 57.8894 58.3091 58.3087 57.6288C58.3087 57.6288 58.3297 57.564 58.4755 57.4461C59.7681 56.0147 60.9149 54.701 62.0616 53.3874C62.0616 53.3874 62.0276 53.3619 62.1957 53.3308C63.5548 52.1664 64.7457 51.033 65.9366 49.8995C66.0848 49.8256 66.2329 49.7517 66.5952 49.5204C67.8557 48.5431 68.9022 47.723 70.1015 46.9553C70.6296 47.2095 71.0049 47.4114 71.3939 47.779C73.7103 49.9314 76.013 51.918 78.3815 54.0585C80.2483 56.7934 82.1111 59.3352 83.7934 61.9913C84.0968 62.4703 83.6582 63.4194 83.3905 64.1731C82.6101 64.5149 82.0013 64.835 81.3926 65.155C81.3926 65.155 81.2912 65.1582 81.0922 65.2216C80.7422 65.4447 80.5911 65.6045 80.4401 65.7643C80.2649 65.8132 80.0897 65.8622 79.6629 66.0177C79.2362 66.3014 79.0612 66.4784 78.8862 66.6554C78.5617 66.7879 78.2372 66.9204 77.6817 67.2063C76.7229 67.9752 75.9951 68.5906 75.2674 69.2061C75.0822 69.335 74.897 69.4638 74.506 69.7665C73.4263 70.9877 72.5524 72.0352 71.6786 73.0827Z" fill="#633C1B"/>
<path d="M27.8664 72.984C24.4194 68.7306 20.2347 65.4746 14.9102 63.4988C14.7353 63.272 14.7325 63.073 14.8404 62.645C15.1422 61.9658 15.3333 61.5155 15.5243 61.0653C15.7692 60.7096 16.0141 60.354 16.4395 59.7596C17.2358 58.5535 17.8516 57.5861 18.6063 56.5232C21.0175 54.0934 23.2897 51.7592 25.5619 49.4249C25.9978 49.1674 26.4337 48.9099 27.1077 48.5002C27.7359 48.0309 28.1259 47.7137 28.6992 47.3487C29.1075 47.1864 29.3326 47.072 29.6882 47.063C30.0312 47.2022 30.2436 47.2362 30.4561 47.2702C30.5984 47.3747 30.7408 47.4791 30.9948 47.8324C31.3578 48.2197 31.6091 48.3582 31.8604 48.4967C32.3515 48.9404 32.8425 49.3841 33.5528 49.9996C34.1052 50.4182 34.4385 50.6649 34.7717 50.9116C34.7717 50.9116 34.8434 50.8199 34.8568 51.0091C36.172 52.4112 37.4737 53.624 38.7755 54.8369C39.6126 55.9998 40.4497 57.1626 41.4545 58.4822C41.6545 58.7303 41.7185 58.776 41.8141 58.776C41.6686 59.2571 41.6759 59.9256 41.3529 60.1892C39.1497 61.9867 36.8348 63.6478 34.6381 65.4529C32.2725 67.3966 30.3851 69.7043 29.4828 72.9373C28.7469 73.0243 28.3066 73.0041 27.8664 72.984Z" fill="#633C1B"/>
<path d="M66.6276 13.6268C67.7188 12.8779 68.7588 12.2384 69.9489 11.5066C73.5323 14.2121 75.8901 17.8928 78.04 21.954C78.8206 24.2673 79.4563 26.4644 80.175 28.6339C80.6178 29.9706 80.0841 30.6384 78.9878 31.4149C75.9277 33.5824 73.0005 35.9376 69.8323 38.3414C69.3066 38.1573 68.9695 37.8504 68.6323 37.5435C67.4114 35.9855 66.1905 34.4275 64.8319 32.807C64.3501 32.2143 64.006 31.6842 63.6619 31.154C63.5 30.918 63.3381 30.6821 63.1294 30.1685C62.9263 29.8222 62.77 29.7536 62.6137 29.685C62.6137 29.685 62.5269 29.571 62.3733 29.4221C60.5066 27.6426 58.7935 26.0121 56.9989 24.304C57.3375 23.657 57.7091 22.9466 58.0808 22.2363C58.2785 22.0284 58.4762 21.8205 58.8433 21.3915C59.7941 20.1968 60.5757 19.2234 61.3573 18.2499C61.8653 17.7717 62.3734 17.2936 63.1167 16.6182C64.278 15.5763 65.2042 14.7314 66.1303 13.8865C66.302 13.9046 66.4507 13.8545 66.6276 13.6268Z" fill="#633C1B"/>
</svg>`

export default PineCone