import classNames from 'classnames'

interface TimelineItemProps {
  day?: string
  subtitle: string
  imgUrl: string
  title: string
  inverted: boolean
  children: React.ReactNode
}
const TimelineItem = ({
  day,
  subtitle,
  children,
  imgUrl,
  title,
  inverted,
}: TimelineItemProps) => {
  return (
    <li className={classNames({ 'timeline-inverted': !!inverted })}>
      <div
        className="timeline-badge"
        style={{ backgroundImage: `url(${imgUrl})` }}
      />
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h3 className="timeline-day">
            <em>{day}</em>
          </h3>
          <h3 className="timeline-title">{title}</h3>
          <span className="subtitle">{subtitle}</span>
        </div>
        <div className="timeline-body">
          <p>{children}</p>
        </div>
      </div>
    </li>
  )
}

export default TimelineItem
