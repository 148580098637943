import { RefObject, useCallback, useEffect, useState } from 'react'

interface Props<E extends HTMLElement> {
  ref: RefObject<E>
  onResize: (boundingClientRect: DOMRect) => void
  onUnmount?: () => void
}

const voidFn = () => {}

const useHtmlResize = <E extends HTMLElement>({
  ref,
  onResize,
  onUnmount = voidFn,
}: Props<E>) => {
  const handleResize = useCallback(
    () => {
      // https://stackoverflow.com/a/21696585 check offsetParent to know if element is visible to user
      if (ref.current && ref.current.offsetParent !== null) {
        onResize(ref.current.getBoundingClientRect())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Missing ref, but we don't want to listen to it
    [ref.current, onResize]
  )

  const [resizeObserver] = useState(
    typeof ResizeObserver !== 'undefined'
      ? new ResizeObserver(handleResize)
      : undefined
  )

  useEffect(
    () => {
      if (ref.current && resizeObserver) {
        const htmlElement = ref.current
        resizeObserver.observe(htmlElement)
        return () => {
          resizeObserver.unobserve(htmlElement)
          onUnmount()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Missing ref and resizeObserver, voluntary
    [ref.current, onUnmount]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps -- Voluntary
  useEffect(handleResize, [ref.current])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])
}

export default useHtmlResize
