interface Props {
  children: React.ReactNode;
  className: string;
  id?: string;
}

const Section = ({ children, className, id }: Props) => {
  return (
    <section id={id} className={`d-flex section ${className}`}>
      {children}
    </section>
  );
};

export default Section;
