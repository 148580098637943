import { launchAnimal } from '../functions/launchAnimal'

const easterStyle = {
  bottom: '-1000px',
  left: '-1000px',
  userSelect: 'none',
  position: 'fixed',
  color: 'purple',
  zIndex: 2500,
  display: 'none',
  fontSize: '5rem',
  cursor: 'pointer',
} as const

const Footer = () => {
  return (
    <>
      <footer
        onClick={() => launchAnimal()}
        className="py-3 bottom-0 bg-green container-fluid box-shadow-top"
      >
        <div className="container user-select-none">
          <p className="text-white text-center my-3">
            Fait avec ❤️ par Léna <span className="playball">&</span> Guillaume
          </p>
        </div>
      </footer>
      <div id="easter" style={easterStyle} />
    </>
  )
}

export default Footer
