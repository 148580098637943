import { Col, Container, Row } from 'react-bootstrap'

import Timeline from './Timeline'
import TimelineItem from './TimelineItem'
import Cover from './Cover'
import Brunch from '../../assets/planning/Brunch.svg'
import Cocktail from '../../assets/planning/Cocktail.svg'
import Diner from '../../assets/planning/Diner.svg'
import Party from '../../assets/planning/Party.svg'
import Refreshment from '../../assets/planning/Refreshment.svg'
import Church from '../../assets/planning/Church.svg'

const Planning = () => {
  return (
    <>
      <Cover />
      <div id="planning" className="bg-sand">
        <Container>
          <Row>
            <Col md={8} className="m-auto text-center">
              <h2>Programme du week-end</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Timeline>
                <TimelineItem
                  day="SAMEDI"
                  title="15h - Cérémonie religieuse"
                  subtitle="Église de la Trinité, 40150 Soorts-Hossegor"
                  imgUrl={Church}
                  inverted={false}
                >
                  Rejoignez-nous à l’église d’Hossegor pour célébrer notre
                  union&nbsp;! La cérémonie débutera à l’heure, donc n’oubliez
                  pas d’anticiper tout imprévu (notamment de parking), afin
                  d’éviter les fameux tracas du centre-ville d’Hossegor.
                </TimelineItem>
                <TimelineItem
                  day="SAMEDI"
                  title="17h - Rafraîchissement & photos"
                  subtitle="Château de Larraldia, 64990 Villefranque"
                  imgUrl={Refreshment}
                  inverted
                >
                  Quittons les Landes pour rejoindre le Pays basque, on se
                  retrouve au Château de Larraldia, on a hâte de vous voir tous
                  là-bas&nbsp;! Afin de laisser le temps à tout le monde
                  d’arriver, un petit rafraîchissement est prévu le temps
                  prendre quelques photos avec nos témoins et familles.
                </TimelineItem>
                <TimelineItem
                  day="SAMEDI"
                  title="17h30 - Cocktail"
                  subtitle="Château de Larraldia, 64990 Villefranque"
                  imgUrl={Cocktail}
                  inverted={false}
                >
                  Une fois que tout le monde est arrivé, c’est parti pour
                  entamer les festivités, en espérant pouvoir profiter des
                  jardins extérieurs durant l'apéritif&nbsp;🤞.
                </TimelineItem>
                <TimelineItem
                  day="SAMEDI"
                  title="20h - Dîner"
                  subtitle="Château de Larraldia, 64990 Villefranque"
                  imgUrl={Diner}
                  inverted
                >
                  À taaable&nbsp;! Prenez place et venez vous régaler avec nous
                  lors d’un repas assis où la bonne humeur sera au rendez-vous.
                </TimelineItem>
                <TimelineItem
                  day="SAMEDI"
                  title="Jusqu’à 4h - Soirée & fiesta"
                  subtitle="Château de Larraldia, 64990 Villefranque"
                  imgUrl={Party}
                  inverted={false}
                >
                  Après le repas, on passe en mode fiesta, la salle se
                  transforme en piste de danse. On veut vous voir vous dandiner
                  et enflammer le dancefloor avec nous&nbsp;!
                </TimelineItem>
                <TimelineItem
                  day="DIMANCHE"
                  title="12h - Brunch"
                  subtitle="Château de Larraldia, 64990 Villefranque"
                  imgUrl={Brunch}
                  inverted
                >
                  Pour nos familles et amis proches, nous serons ravis de vous
                  retrouver le dimanche pour un brunch à partir de 12h.
                  L’occasion de se retrouver après la soirée et de finir en
                  beauté ce week-end de folie&nbsp;!
                </TimelineItem>
              </Timeline>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Planning
