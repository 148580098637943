export const ROUTE_NAME = {
  HOME: 'Home',
  PLANNING: 'Planning',
  TRAVEL: 'Travel',
  DETAILS: 'Details',
  REGISTRY: 'Registry',
  UPCOMING: 'Upcoming',
} as const

export const ROUTES: Record<keyof typeof ROUTE_NAME, string> = {
  HOME: '/',
  PLANNING: '/planning',
  TRAVEL: '/travel',
  DETAILS: '/details',
  REGISTRY: '/registry',
  UPCOMING: '/upcoming',
}

export const RSVP = 'https://withjoy.com/lena-guillaume'
export const REGISTRY =
  'https://www.millemercismariage.com/lena-guillaume/liste.html'
