import kiwi from '../assets/elements/kiwi'
import leaves from '../assets/elements/leaves'
import pineCone from '../assets/elements/pineCone'
import sun from '../assets/elements/sun'
import surfboard from '../assets/elements/surfboard'
import trek from '../assets/elements/trek'

const animals = [kiwi, kiwi, leaves, pineCone, sun, surfboard, trek]

function animal() {
  return animals[Math.floor(Math.random() * animals.length)]
}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function replaceSvgWidth(svgString: string, newWidth: number) {
  const regex = /(<svg[^>]*width=")([^"]*)/g
  return svgString.replace(regex, `$1${newWidth}`)
}

export function launchAnimal(givenAnimal?: string): void {
  const easterNode = document.getElementById('easter')
  if (!easterNode) {
    return
  }
  const node = easterNode.cloneNode() as HTMLElement
  node.removeAttribute('id')
  document.body.append(node)

  node.innerHTML = replaceSvgWidth(
    givenAnimal || animal(),
    getRandomInt(50, 80)
  )
  node.style.display = ''
  node.style.fontSize = `${Math.round(4 + 2 * Math.random())}rem`
  const width = node.offsetWidth
  const height = node.offsetHeight
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const margin = 10

  const ay = -200
  const fx = 0.997
  const fy = 1
  const elastic = 0.7
  const elastic2 = 0.9
  const targetFps = 30

  const state = {
    x: Math.random() * windowWidth,
    y: margin,
    vx: 200.0 + (Math.random() - 0.5) * 50,
    vy: 500.0 + (Math.random() - 0.5) * 150,
    ts: performance.now(),
  }

  node.addEventListener(
    'click',
    function (e) {
      e.preventDefault()
      state.vx *= 10 * (0.5 + Math.random())
      state.vy *= 10 * (0.5 + Math.random())
    },
    false
  )

  const tick = (ts: number) => {
    let dt = ts - state.ts

    if (dt > 1000 / targetFps) {
      dt /= 1000
      state.x = state.x + state.vx * dt
      state.y = state.y + state.vy * dt
      state.vx = state.vx * fx
      state.vy = state.vy * fy + ay * dt
      state.ts = ts

      if (
        (state.x < margin && state.vx < 0) ||
        (state.x + width + margin > windowWidth && state.vx > 0)
      ) {
        state.vx = -state.vx * elastic
        state.vy = state.vy * elastic2
      }
      if (
        (state.y < margin && state.vy < 0) ||
        (state.y + height + margin > windowHeight && state.vy > 0)
      ) {
        state.vy = -state.vy * elastic
        state.vx = state.vx * elastic2
      }

      updateNodePos()
    }

    if (Math.abs(state.vx) > 25 || Math.abs(state.vy) > 25) {
      window.requestAnimationFrame(tick)
    } else {
      node.style.transition = 'bottom 5s'
      node.style.bottom = '-500px'
    }
  }

  const updateNodePos = () => {
    node.style.left = `${Math.round(state.x)}px`
    node.style.bottom = `${Math.round(state.y)}px`
  }

  tick(state.ts)
}
