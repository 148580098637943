import { Col, Container, Row } from 'react-bootstrap'
import fairePart from '../../assets/faire-part.svg'
import EngagementImage from '../../assets/engagement.png'
import RSVPButton from '../../components/RSVPButton'
import usePositionEngagementImage from './usePositionsEngagementImage'

const RSVPButtonContainer = ({ className = '' }: { className: string }) => (
  <div className={`text-center ${className}`}>
    <RSVPButton label="Confirmer ma présence" size="lg" />
  </div>
)

const Cover = () => {
  const { ref, positionProperties } = usePositionEngagementImage()

  return (
    <div className="bg-sand">
      <Container>
        <Row className="cover-container">
          <Col md={6} sm={12} className="invitation-card">
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <div className="text-left">
                <h1>
                  Mariage
                  <br />
                  Léna <span className="playball">&</span> Guillaume
                </h1>
                <p className="d-xxl-none">
                  Nous sommes heureux de vous inviter pour célébrer ensemble ce
                  moment unique&nbsp;!
                </p>
                <p className="d-none d-xxl-block">
                  Nous sommes heureux de vous inviter pour célébrer ensemble
                  <br />
                  ce moment unique&nbsp;!
                </p>
              </div>

              <h2>28.09.2024</h2>
              <RSVPButtonContainer className="d-none d-lg-block" />
            </div>
          </Col>
          <Col md={6} sm={12} className="py-5 h-100">
            <div className="d-flex flex-column h-100 position-relative align-items-center justify-content-center">
              <img
                ref={ref}
                src={fairePart}
                alt="Faire-part"
                className="invitation"
                style={
                  positionProperties
                    ? {
                        paddingBottom: positionProperties.paddingBottom,
                      }
                    : {}
                }
              />
              {!!positionProperties && (
                <div
                  className="engagement-image"
                  style={{
                    top: positionProperties.top,
                    left: positionProperties.left,
                    width: positionProperties.width,
                    height: positionProperties.height,
                    backgroundImage: `url(${EngagementImage})`,
                  }}
                />
              )}
            </div>
            <RSVPButtonContainer className="mt-5 d-xs-block d-sm-block d-md-block d-lg-none" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Cover
