import classNames from 'classnames'
import ExternalLink from '../../components/ExternalLink'
import Section from '../../components/Section'
import { Container, Card, Col, Row } from 'react-bootstrap'
import React from 'react'

import guethary from '../../assets/guethary.jpg'
import seignosse from '../../assets/seignosse.jpg'
import gaubeLake from '../../assets/gaube-lake.jpg'
import spain from '../../assets/spain.jpg'
import paysBasque from '../../assets/pays-basque.jpg'

const IMG_WIDTH = '340px'
const IMG_HEIGHT = '200px'

const CARDS = [
  {
    title: 'Biarritz - Bidart - Guéthary',
    description: (
      <>
        Faites un tour à la plage à Biarritz et profitez-en pour explorer la
        ville&nbsp;: Rocher de la Vierge, Aquarium, Église Sainte-Eugénie, Côte
        des Basques, La Grande Plage … Prolongez ensuite votre séjour aux
        villages de Guéthary et Bidart.
      </>
    ),
    img: guethary,
  },
  {
    title: 'Capbreton - Hossegor - Seignosse',
    description: (
      <>
        Longez la côte et explorez la nature environnante des grandes plages des
        Landes, prisées des surfeurs pour leurs vagues (attention aux baïnes).
        Centre-ville, lac, port, sortie en bateaux … Vous trouverez de quoi vous
        détendre dans ces villes charmantes.
      </>
    ),
    img: seignosse,
  },
  {
    title: 'Pays Basque',
    description: (
      <>
        Partez explorer les charmants villages basques comme Ainhoa, Sare,
        Espelette ou La Bastide-Clairence et perdez vous dans leurs ruelles
        pavées tout en dégustant les spécialités locales : piment d’Espelette,
        axoa, chipirons, patxaran, Ossau-Iraty, gâteau basque, etc.
      </>
    ),
    img: paysBasque,
  },
  {
    title: 'Randonnées dans le Pays Basque - Pyrénées',
    description: (
      <>
        Le Pays basque et les Pyrénées offrent des vues spectaculaires,
        n’hésitez pas à partir à l’aventure à l'assaut de la Rhune, du sentier
        du Littoral, du Mondarrain, du Mont Baïgura ou encore du Parc national
        des Pyrénées (au départ de Cauterets).
      </>
    ),
    img: gaubeLake,
  },
  {
    title: 'Espagne - San Sebastián (Donostia)',
    description: (
      <>
        Partez explorer San Sebastián, la vieille ville offre une ambiance
        charmante avec des rues étroites et des bars à tapas animés.
      </>
    ),
    img: spain,
  },
]

const Point = ({
  title,
  description,
  img,
  reverse,
}: {
  title: string
  description: React.ReactNode
  img: string
  reverse: boolean
}) => {
  return (
    <Card
      className={classNames('card flex-row mb-4 box-shadow', {
        'flex-row-reverse': !!reverse,
      })}
    >
      <Card.Body className="p-4 d-flex flex-column align-items-start">
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
      <Card.Img
        width={IMG_WIDTH}
        height={IMG_HEIGHT}
        style={{ width: IMG_WIDTH, height: IMG_HEIGHT }}
        className="card-img flex-auto d-none d-lg-block"
        src={img}
      />
    </Card>
  )
}

const Interests = () => {
  return (
    <Section id="interests" className="bg-tertiary interests">
      <Container className="px-4 py-5">
        <h2>Les points d’intérêts aux alentours</h2>
        <Row>
          <Col md={10} lg={8}>
            <p>
              <strong>
                Si vous souhaitez prolonger votre séjour, nous vous recommandons
                les destinations suivantes&nbsp;:
              </strong>
              <br />
              <em>
                Pour aller plus loin, n'hésitez pas à visiter les sites
                touristique des régions&nbsp;:&nbsp;
                <ExternalLink href="https://www.tourismelandes.com/">
                  Landes
                </ExternalLink>
                &nbsp;|&nbsp;
                <ExternalLink href="https://www.en-pays-basque.fr/">
                  Pays basque
                </ExternalLink>
                .
              </em>
            </p>
          </Col>
        </Row>
        <Row className="points-container">
          {CARDS.map((card, index) => (
            <Col key={card.title} xs={12}>
              <Point
                reverse={index % 2 === 0}
                title={card.title}
                description={card.description}
                img={card.img}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default Interests
