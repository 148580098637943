import { Row, Col } from "react-bootstrap";
import saveTheDate from "../../assets/save-the-date.svg";

const Cover = () => {
  return (
    <Row className="bg-sand cover-container">
      <Col>
        <img
          src={saveTheDate}
          alt="save the date"
          className="img-fluid save_the_date align-self-end"
        />
      </Col>
    </Row>
  );
};

export default Cover;
