import { Button } from 'react-bootstrap'
import { RSVP } from '../constants/routes'

const RSVPButton = ({
  className = undefined,
  label = 'RSVP',
  onClick = undefined,
  size = 'lg',
}: {
  className?: string
  label?: string
  onClick?: () => void
  size: 'sm' | 'md' | 'lg'
}) => (
  <Button
    variant="primary"
    href={RSVP}
    target="_blank"
    rel="noopener noreferrer"
    size={size === 'md' ? undefined : size}
    className={className}
    onClick={onClick}
  >
    {label || 'Confirmer ma présence'}
  </Button>
)

export default RSVPButton
