import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
} from 'react-bootstrap'
import Section from '../../components/Section'
import ExternalLink from '../../components/ExternalLink'

const Option = ({
  title,
  churchIndications,
  castleIndications,
}: {
  title: string
  churchIndications: string
  castleIndications: string
}) => {
  return (
    <Card className="my-4">
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <p className="mb-3">⛪ : {churchIndications}</p>
        <p>🍸 : {castleIndications}</p>
      </CardBody>
    </Card>
  )
}

const CHURCH_GOOGLE_MAP =
  'https://www.google.com/maps/place//data=!4m2!3m1!1s0xd515b717030a8c5:0xab2b63a76d0d84a6?sa=X&ved=1t:8290&ictx=111'
const CASTLE_GOOGLE_MAP =
  'https://www.google.com/maps/place//data=!4m2!3m1!1s0xd513f300d97027b:0xcef006132fa817ce?sa=X&ved=1t:8290&ictx=111'

const Coming = () => {
  return (
    <Section id="coming" className="bg-sand coming">
      <Container className="px-4 py-5">
        <h2>Comment venir&nbsp;?</h2>
        <Row>
          <Col md={10} lg={8}>
            <p className="mb-1">
              Notre journée de mariage se déroulera sur 2 lieux
              différents&nbsp;:
            </p>
            <ul>
              <li>
                à{' '}
                <ExternalLink href={CHURCH_GOOGLE_MAP}>
                  l’Église la Trinité
                </ExternalLink>{' '}
                à Hossegor pour la cérémonie religieuse
              </li>
              <li>
                au{' '}
                <ExternalLink href={CASTLE_GOOGLE_MAP}>
                  Château de Larraldia
                </ExternalLink>{' '}
                à Villefranque pour le cocktail / dîner
              </li>
            </ul>
            <p>
              <strong>
                Nous vous recommandons de vous déplacer de l'Église au Château
                en voiture (~30 min).
              </strong>
              <br />
              N’hésitez pas à vous organiser avec les autres invités pour
              minimiser le nombre de voitures nécessaires.
            </p>
            <p>
              Pour rejoindre les lieux de notre mariage,{' '}
              <strong>
                vous pouvez utiliser divers moyens de transport&nbsp;:
              </strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Option
              title="🏎️ Voiture"
              churchIndications="Prenez l’autoroute A63 et sortez à Capbreton (sortie 8) pour vous rendre à l’Église."
              castleIndications="Pour vous rendre au Château de Larraldia, prenez l’autoroute A63 en direction de San Sebastián, changez pour l’A64 direction Bayonne-Mousserolles, puis sortez de suite en prenant la sortie 1 en direction de Villefranque (sortie IKEA)."
            />
            <Option
              title="🚆 Train"
              churchIndications="La gare la plus proche est celle de Bayonne, à quelques kilomètres seulement d’Hossegor et du Château. Vous pouvez également arriver à la gare de Dax."
              castleIndications="À partir d’une des gares, vous pouvez facilement prendre un taxi pour rejoindre notre lieu de célébration ou vous organiser avec d’autres invités pour louer une voiture."
            />
            <Option
              title="✈️ Avion"
              churchIndications="Si vous arrivez de l’international, l'aéroport le plus proche est celui de Biarritz (ou ceux de Bordeaux, Pau, San Sebastián)."
              castleIndications="À partir d’un des aéroports, vous pouvez facilement prendre un taxi pour rejoindre notre lieu de célébration ou vous organiser avec d’autres invités pour louer une voiture."
            />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Coming
