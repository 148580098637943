import { Col, Container } from 'react-bootstrap'
import Section from '../../components/Section'
import RSVPButton from '../../components/RSVPButton'

const AnswerSection = () => {
  return (
    <Section className="answer bg-sand">
      <Container>
        <Col
          className="h-100 d-flex flex-column align-items-center justify-content-center mx-auto"
          md={10}
          xs={12}
        >
          <h3 className="green">Au plaisir de vous voir !</h3>
          <RSVPButton size="lg" label="Je confirme ma présence" />
        </Col>
      </Container>
    </Section>
  )
}

export default AnswerSection
