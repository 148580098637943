import { Container, Row, Col } from 'react-bootstrap'
export const URL =
  'https://www.google.com/maps/d/u/0/embed?mid=18NEwow_1gwHGIFoQyOe9X7kVqABglkc&ehbc=2E312F'

const Map = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <iframe title="map" src={URL} id="map" />
        </Col>
      </Row>
    </Container>
  )
}

export default Map
