import { Col, Container, Row } from 'react-bootstrap'
import EngagementImage from '../assets/engagement.png'
import RegistryButton from '../components/RegistryButton'

const Registry = () => {
  return (
    <div id="registry" className="bg-sand">
      <Container className="px-4 py-5">
        <Row>
          <Col md={8} xs={12} className="m-auto">
            <Row>
              <Col md={8} className="m-auto text-center">
                <h2>Liste de mariage</h2>
              </Col>
            </Row>
            <Row className="my-2">
              <Col
                lg={6}
                xs={12}
                className="d-flex flex-column justify-content-center"
              >
                <p>
                  Votre présence est ce qui compte le plus pour nous, et nous
                  sommes reconnaissants de pouvoir vivre ce jour entourés de nos
                  proches & amis&nbsp;❣️.
                </p>
                <p>
                  Pour ceux d'entre vous qui souhaitent marquer cette étape avec
                  un geste supplémentaire, nous avons créé une petite liste de
                  mariage remplie d'idées qui nous aideront à démarrer notre
                  nouvelle vie ensemble. Une urne sera également prévue sur
                  place le jour J pour ceux qui le souhaitent.
                </p>
                <p className="mb-0">
                  <strong>
                    Mais nous tenons à souligner que votre venue et vos sourires
                    le jour de notre mariage sont les plus beaux cadeaux que
                    nous pourrions recevoir.
                  </strong>
                </p>
                <div className="my-5 text-center">
                  <RegistryButton />
                </div>
              </Col>
              <Col lg={6} xs={12}>
                <img
                  width="100%"
                  src={EngagementImage}
                  alt="léna et guillaume"
                  className="image-container"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Registry
