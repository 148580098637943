import ProgrammeSection from './Programme'
import TravelSection from './Travel'
import RegistrySection from './Registry'
import AnswerSection from './Answer'
import Cover from './Cover'

const Home = () => {
  return (
    <div id="home">
      <Cover />
      <ProgrammeSection />
      <TravelSection />
      <RegistrySection />
      <AnswerSection />
    </div>
  )
}

export default Home
