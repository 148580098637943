import kiwi from '../../assets/kiwi.svg'
import kiwiStringSvg from '../../assets/elements/kiwi'

import { launchAnimal } from '../../functions/launchAnimal'

const MovingKiwi = () => {
  return (
    <div id="moving-kiwi-container">
      <img
        src={kiwi}
        alt="kiwi"
        className="img-fluid kiwi"
        onClick={() => launchAnimal(kiwiStringSvg)}
      />
    </div>
  )
}

export default MovingKiwi
