import { Col, Container } from 'react-bootstrap'
import Section from '../../components/Section'
import RegistryButton from '../../components/RegistryButton'

const RegistrySection = () => {
  return (
    <Section id="registry" className="bg-white">
      <Container className="px-4 py-5">
        <Col md={12} lg={8}>
          <h2>Liste de mariage</h2>
          <p className="text-left mb-3">
            Votre présence est ce qui compte le plus pour nous, et nous sommes
            reconnaissants de pouvoir vivre ce jour entourés de nos proches &
            amis&nbsp;❣️.
          </p>
          <p className="text-left">
            Pour ceux d'entre vous qui souhaitent marquer cette étape avec un
            geste supplémentaire, nous avons créé une petite liste de mariage
            remplie d'idées qui nous aideront à démarrer notre nouvelle vie
            ensemble. Une urne sera également prévue sur place le jour J pour
            ceux qui le souhaitent.
          </p>
          <p>
            <strong>
              Mais nous tenons à souligner que votre venue et vos sourires le
              jour de notre mariage sont les plus beaux cadeaux que nous
              pourrions recevoir.
            </strong>
          </p>
        </Col>
        <RegistryButton />
      </Container>
    </Section>
  )
}

export default RegistrySection
