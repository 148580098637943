import { Card, CardBody, Row, Col, Container, Button } from 'react-bootstrap'
import Section from '../../components/Section'
import ExternalLink from '../../components/ExternalLink'
import { URL as GOOGLE_MAP_URL } from './Map'
import { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const DEFAULT_CARDS = [
  {
    title: 'Gîtes à proximité du Château',
    description: (
      <>
        <p>
          Privilégiez cette option si vous êtes un groupe, cela nous semble être
          le plus adapté.
        </p>
        <p>
          Il est possible de trouver un gîte assez proche pour rentrer à pied.
        </p>

        <p className="mb-0">Quelques options :</p>
        <ul>
          <li>
            <ExternalLink href="https://www.chambres-hotes.fr/chambres-hotes_chambres-d-hotes-lascaray_villefranque_23858.htm">
              Chamb. d'hôtes Lascaray - 2 à 7 pers
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.kurutcheta.com/">
              Kurutcheta - 1 à 15 pers
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://mendi-hegian.fr/">
              Gîte Mendi Hegian - 4 à 6 pers
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.airbnb.fr/rooms/24504820?location=Villefranque%2C%20France&check_in=2024-09-28&source_impression_id=p3_1712698188_kelJDu4%2Fw%2BohW2Nx&previous_page_section_name=1001&federated_search_id=1409d640-b743-4eb3-b913-b2230ab94ca4&guests=1&adults=1&check_out=2024-09-29">
              Airbnb - 4 à 5 pers
            </ExternalLink>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Hôtels - Sortie A63',
    description: (
      <>
        <p>Autoroute A63, Sortie 6 - Bayonne Centre.</p>
        <p>
          C’est là que vous trouverez la majorité des hôtels les plus proches de
          notre lieu de mariage (env. 10 min en voiture).
        </p>
        <p>
          Si besoin, vous pourrez ensuite réserver un taxi pour rentrer en toute
          tranquillité, des numéros de taxis sont indiqués sur la page&nbsp;
          <Link to={`${ROUTES.DETAILS}#taxis`}>Questions</Link>.
        </p>
        <p className="mb-0">Quelques options :</p>
        <ul>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/ibis-budget-bayonne.fr.html?aid=320858&label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&all_sr_blocks=58228802_376293427_2_42_0;checkin=2024-09-28;checkout=2024-09-29;dest_id=-1410844;dest_type=city;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=58228802_376293427_2_42_0;hpos=1;matching_block_id=58228802_376293427_2_42_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=58228802_376293427_2_42_0__9279;srepoch=1712699232;srpvid=e9d6992cdc460154;type=total;ucfs=1&#hotelTmpl">
              Hôtel Ibis Budget - 2*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/adonis-bayonne.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=54548502_88555339_2_2_0&atlas_src=sr_iw_title">
              Hôtel Adonis - 3*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/comfort-bayonne-biarritz.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=35449_88559770_1_42_0&atlas_src=sr_iw_title">
              Hôtel Loreak - 3*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/ibis-bayonne.fr.html?aid=320858&label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&all_sr_blocks=54200904_376291208_2_34_0;checkin=2024-09-28;checkout=2024-09-29;dest_id=-1410844;dest_type=city;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=54200904_376291208_2_34_0;hpos=1;matching_block_id=54200904_376291208_2_34_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=54200904_376291208_2_34_0__11675;srepoch=1712699286;srpvid=8d1199485e64014c;type=total;ucfs=1&#hotelTmpl">
              Hôtel Ibis Centre - 3*
            </ExternalLink>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Hôtels - Bayonne Centre',
    description: (
      <>
        <p>
          Cette option est légèrement plus loin du lieu de réception (env. 15
          min en voiture), mais elle vous permettra de profiter de la jolie
          ville de Bayonne avant ou après notre mariage.
        </p>
        <p>
          Si besoin, vous pourrez ensuite réserver un taxi pour rentrer en toute
          tranquillité, des numéros de taxis sont indiqués sur la page&nbsp;
          <Link to={`${ROUTES.DETAILS}#taxis`}>Questions</Link>.
        </p>
        <p className="mb-0">Quelques options :</p>
        <ul>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/cote-basque.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=17231806_88532465_0_2_0&atlas_src=sr_iw_title">
              Hôtel Côte Basque - 2*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/le-port-neuf.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=54278109_325732821_0_0_0&atlas_src=sr_iw_title">
              Hôtel Le Port Neuf - 2*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/le-bayonne-ha-tel.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=38666909_381993280_1_42_0&atlas_src=sr_iw_title">
              Hôtel Le Bayonne - 4*
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.booking.com/hotel/fr/des-basses-pyrenees.fr.html?label=gog235jc-1FCAsoTUIYY29tZm9ydC1iYXlvbm5lLWJpYXJyaXR6SA1YA2hNiAEBmAENuAEHyAEM2AEB6AEB-AEKiAIBqAIDuALU2tawBsACAdICJGYyMTUxNTJkLWU1OTYtNGJlYi05NmMwLTdhNmFmNTExN2I4NtgCBeACAQ&sid=c425e9bf4520d7b468485e79336c9fa0&aid=320858&ucfs=1&checkin=2024-09-28&checkout=2024-09-29&dest_id=-1410844&dest_type=city&group_adults=1&no_rooms=1&group_children=0&matching_block_id=141667604_387758342_0_2_0&atlas_src=sr_iw_title">
              Hôtel des Basses Pyrénées - 4*
            </ExternalLink>
          </li>
        </ul>
      </>
    ),
  },
]

const OptionCard = ({
  main,
  title,
  description,
}: {
  main: boolean
  title: string
  description: React.ReactNode
}) => {
  return (
    <Card className={classNames({ main: !!main })}>
      <CardBody>
        <h3>{title}</h3>
        {description}
      </CardBody>
    </Card>
  )
}
const Accomodation = () => {
  const [cards, setCards] = useState(DEFAULT_CARDS)

  const nextCard = () => {
    setCards((prev) => {
      const newCards = []
      newCards[0] = prev[1]
      newCards[1] = prev[2]
      newCards[2] = prev[0]

      return newCards
    })
  }

  const previousCard = () => {
    setCards((prev) => {
      const newCards = []
      newCards[0] = prev[2]
      newCards[1] = prev[0]
      newCards[2] = prev[1]

      return newCards
    })
  }

  return (
    <Section id="accomodation" className="bg-white accomodation">
      <Container className="px-4 py-5">
        <h2>Où loger ?</h2>
        <Row>
          <Col md={10} lg={8}>
            <p>
              <strong>
                Nous vous suggérons 3 options pour loger à proximité du Château
                (lieu de réception).
              </strong>
              <br />
              <em>
                Pour chacune des options, nous vous avons listé quelques idées
                de logements{' '}
                <ExternalLink href={GOOGLE_MAP_URL}>
                  sur cette carte.
                </ExternalLink>
              </em>
            </p>
          </Col>
        </Row>
        <Row className="card-container justify-content-center align-items-center">
          <Col xs={0} lg={1}>
            <Button
              variant="unstyled"
              className="mx-2 font-size-2-rem"
              onClick={previousCard}
            >
              {'<'}
            </Button>
          </Col>
          <Col xs={12} lg={10}>
            <Row>
              {cards.map((card, index) => (
                <Col key={card.title} xs={12} lg={4} className="d-flex">
                  <OptionCard
                    main={index === 1}
                    title={card.title}
                    description={card.description}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={0} lg={1}>
            <Button
              variant="unstyled"
              className="mx-2 font-size-2-rem"
              onClick={nextCard}
            >
              {'>'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Accomodation
