import { Accordion, Col, Container, Row } from 'react-bootstrap'

import AccordionItem from './AccordionItem'
import MovingKiwi from './MovingKiwi'
import carteParkingHossegor from '../../assets/carte-parking-hossegor.png'
import ExternalLink from '../../components/ExternalLink'
import { RSVP, REGISTRY } from '../../constants/routes'
import { useLocation } from 'react-router-dom'

const Details = () => {
  const location = useLocation()

  return (
    <div id="details">
      <MovingKiwi />
      <Container className="px-4 py-5">
        <Row>
          <Col md={8} className="m-auto text-center">
            <h2 className="mb-2">Détails pratiques</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8} className="m-auto py-3">
            <Accordion
              defaultActiveKey={location.hash === '#taxis' ? '4' : '0'}
            >
              <AccordionItem
                itemKey={0}
                title="Quelle est la date limite de réponse ?"
              >
                Comme indiqué sur le faire-part, une réponse est souhaitée{' '}
                <strong>avant le 31 juillet 2024</strong>, afin que nous
                puissions nous organiser au mieux. <br /> <br />
                Vous pouvez répondre directement sur le site{' '}
                <ExternalLink href={RSVP}>en cliquant sur RSVP</ExternalLink>,
                ou bien nous écrire et nous compléterons le formulaire pour
                vous.
              </AccordionItem>
              <AccordionItem
                itemKey={1}
                title="Existe-t-il une liste de mariage ?"
              >
                Notre plus beau cadeau c’est de vous avoir parmi nous&nbsp;!
                Toutefois, pour celles et ceux qui nous ont demandé et
                souhaitent contribuer à préparer notre voyage de noces, nous
                avons mis en place une liste de mariage{' '}
                <ExternalLink href={REGISTRY}>disponible ici.</ExternalLink>
              </AccordionItem>
              <AccordionItem
                itemKey={2}
                title="Les enfants sont-ils les bienvenus ?"
              >
                Nous comprenons que les familles peuvent souhaiter inclure leurs
                enfants dans les célébrations, mais bien que nous aimions
                beaucoup les pitchouns&nbsp;🐣, nous ne serons pas en capacité
                de les accueillir dans de bonnes conditions lors du mariage.
                <br />
                En revanche, ils peuvent nous retrouver pour le brunch du
                dimanche.
              </AccordionItem>
              <AccordionItem
                itemKey={3}
                title="Est-ce que je peux venir accompagné(e) ?"
              >
                Si vous faîtes référence à vos enfants, reportez-vous à la
                question précédente. Si vous parlez d’un +1, veuillez noter que
                les invitations sont nominatives et liées avec le nom de chaque
                invité accompagnant.
              </AccordionItem>
              <AccordionItem itemKey={4} title="Comment repartir de la soirée?">
                Nous voulons que vous puissiez profiter de notre mariage jusqu'à
                la fin sans vous soucier du transport. C'est pourquoi nous vous
                recommandons de réserver en amont un taxi pour votre retour.{' '}
                <strong>La soirée se terminant à 4h du matin</strong>, cela vous
                laissera le temps de dire au revoir à tout le monde et de
                rentrer en toute sécurité.
                <br />
                <br />
                Pour les taxis&nbsp;🚕, nous vous suggérons de passer par (au
                choix):
                <ul>
                  <li>
                    VTC Rachel Garicoitz - rachel@vitici64.fr - 07 45 11 79 39
                  </li>
                  <li>
                    Biarritz Chauffeur Service -
                    info@biarritzchauffeurservice.com - 06 63 39 15 69
                  </li>
                  <li>So'Driv - contact@sodrive.fr - 06 15 23 92 58</li>
                  <li>Les taxis de Bayonne - 05 59 59 48 48</li>
                </ul>
              </AccordionItem>
              <AccordionItem
                itemKey={5}
                title="Est-ce qu'il y aura un parking sur place ?"
              >
                Un parking sécurisé avec{' '}
                <strong>plus de 100 places disponibles</strong> est à
                disposition au Château Larraldia. Vous pourrez facilement vous
                garer et laisser votre voiture sur place pour profiter
                pleinement de la soirée.
                <br />
                <br />
                En revanche, pour l'Église d'Hossegor,{' '}
                <strong>
                  il faudra utiliser les parkings mis en place par la ville.
                  Vous pouvez les trouver sur la carte suivante&nbsp;:
                </strong>{' '}
                <ExternalLink href={carteParkingHossegor}>Carte</ExternalLink>.
                Plus d'informations sont également disponibles sur ce{' '}
                <ExternalLink href="https://www.soorts-hossegor.fr/vivre-ici/mobilite-et-stationnement#stationnement-et-fps">
                  lien
                </ExternalLink>
                .<br />
                <br />
                <strong>
                  Pensez à arriver en avance pour éviter tous tracas.
                </strong>
                <br />
                Merci d'avance,
              </AccordionItem>
              <AccordionItem itemKey={6} title="Comment dois-je m'habiller ?">
                Pas de dress code ou de couleur imposée pour les invités. Tenue
                élégante ou plus décontractée, l’important est que vous
                choisissiez une tenue dans laquelle vous vous sentez à l'aise
                pour swinguer. Par précaution, toujours penser à une petite
                laine.
                <br />
                NB : les témoins de la mariée seront en vert.
              </AccordionItem>
              <AccordionItem
                itemKey={7}
                title="Comment préciser mes allergies alimentaires ?"
              >
                Si vous avez des allergies ou des restrictions alimentaires
                spécifiques, aucun souci, veuillez nous les indiquer lors du
                RSVP concernant votre présence le jour J. Cela nous permettra
                d’en informer notre traiteur afin qu'il puisse préparer une
                option adaptée à vos besoins.
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Details
