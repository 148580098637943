import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { Offcanvas } from 'react-bootstrap'
import classnames from 'classnames'
import RSVPButton from './RSVPButton'

const PageLink = ({
  label,
  path,
  handleClick,
}: {
  label: React.ReactNode
  path: ValueOf<typeof ROUTES>
  handleClick: (value: boolean) => void
}) => {
  const location = useLocation()

  const isActive = path === location.pathname

  return (
    <Nav.Link
      onClick={() => handleClick(isActive)}
      as={Link}
      active={isActive}
      to={path}
    >
      {label}
    </Nav.Link>
  )
}

const NavBar = () => {
  const [expanded, setExpanded] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const onClickLink = (isActive?: boolean) => {
    setExpanded(false)

    if (isActive) {
      window.scrollTo(0, 0)
    }
  }

  const handleScroll = () => {
    const offset = window.scrollY

    if (offset > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <Navbar
      className={classnames('bg-body-tertiary', {
        'shadow-sm': scrolled,
      })}
      sticky="top"
      expand="lg"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="justify-content-center">
            <Nav>
              <PageLink
                handleClick={onClickLink}
                label="Accueil"
                path={ROUTES.HOME}
              />
              <PageLink
                handleClick={onClickLink}
                label="Programme"
                path={ROUTES.PLANNING}
              />
              <PageLink
                handleClick={onClickLink}
                label={
                  <>
                    Voyager <span className="playball">&</span> Séjourner
                  </>
                }
                path={ROUTES.TRAVEL}
              />
              <PageLink
                handleClick={onClickLink}
                label="Questions"
                path={ROUTES.DETAILS}
              />
              <PageLink
                handleClick={onClickLink}
                label="Liste de mariage"
                path={ROUTES.REGISTRY}
              />
              <RSVPButton
                label="RSVP"
                className="rsvp"
                size="md"
                onClick={onClickLink}
              />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default NavBar
