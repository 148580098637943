import { Card, Button, Col, Container, Row } from 'react-bootstrap'
import Section from '../../components/Section'
import { Link } from 'react-router-dom'
import itinerary from '../../assets/itinerary.jpg'
import hotel from '../../assets/hotel.jpg'
import region from '../../assets/region.jpg'

interface StepProps {
  title: string
  linkLabel: React.ReactNode
  href: string
  imgUrl: string
}

const Step = ({ title, linkLabel, href, imgUrl }: StepProps) => {
  return (
    <Col lg={4} md={12}>
      <Card className="h-100 shadow-sm">
        <Card.Img variant="top" src={imgUrl} />
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Text>{title}</Card.Text>
          <div className="d-flex justify-content-center align-items-center">
            <Link to={href}>
              <Button variant="link">{linkLabel}</Button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

const TravelSection = () => {
  return (
    <Section className="bg-sand">
      <Container className="px-4 py-5">
        <h2 className="pb-2">
          Voyager <span className="playball">&</span> Séjourner
        </h2>
        <Row className="g-4 py-5">
          <Step
            title="Pour l'itinéraire, vous trouverez des indications simples pour vous rendre sur les lieux."
            linkLabel={
              <>
                CARTE <span className="playball">&</span> ITINÉRAIRES
              </>
            }
            href="/travel#coming"
            imgUrl={itinerary}
          />
          <Step
            title="Que vous cherchiez un hôtel, un gîte ou un airbnb, vous devriez pouvoir trouver votre bonheur&nbsp;!"
            linkLabel="OPTIONS D’HÉBERGEMENTS"
            href="/travel#accomodation"
            imgUrl={hotel}
          />
          <Step
            title="Nous vous avons listé quelques suggestions de choses sympas à faire dans la région."
            linkLabel="NOS RECOMMANDATIONS"
            href="/travel#interests"
            imgUrl={region}
          />
        </Row>
      </Container>
    </Section>
  )
}

export default TravelSection
