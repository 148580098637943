import Accordion from 'react-bootstrap/Accordion'

interface Props {
  itemKey: number
  title: string
  children: React.ReactNode
}

const AccordionItem = ({ itemKey, title, children }: Props) => {
  return (
    <Accordion.Item eventKey={`${itemKey}`}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  )
}

export default AccordionItem
